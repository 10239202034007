var site = site || {};
site.userInfoCookie = site.userInfoCookie || {};
(function($) {

  /**
   * Loyalty Marketing Mobile
   */
  Drupal.behaviors.ELB_loyalty_market = {
    attach: function(context, settings) {
      // add loyalty state
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var hasLoyalty = persistentCookie.is_loyalty_member - 0;
      var $landing = $('.loyalty_market');
      if(hasLoyalty) {
        $landing.addClass('is_loyalty_member');
      }
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var firstName = persistentCookie.first_name || '';
      var $loyaltyTermsCheckbox = $('.js-loyalty-required-terms', context);
      var $gdprInfoLink = $('.loyalty_join_signin .js-info-link-gdpr', context);
      var userDetected;
      firstName = firstName.replace(/\+/g, ' ');
      userDetected = !!firstName;
      signedIn = (signedIn === '1') ? true : false;

      if (signedIn) {
        if (hasLoyalty) {
          $loyaltyTermsCheckbox.addClass('hidden');
          $gdprInfoLink.addClass('hidden');
        } else {
          $loyaltyTermsCheckbox.removeClass('hidden');
        }
      } else {
        if (userDetected || hasLoyalty) {
          $loyaltyTermsCheckbox.removeClass('hidden');
        } else {
          $loyaltyTermsCheckbox.addClass('hidden');
          $gdprInfoLink.addClass('hidden');
        }
      }

      //hook up overlays
      site.loyalty.enrollmentBtn($landing);
      site.loyalty.signInBtn($landing);
      //call callback after html landing loads to visualise frontend changes (like gdpr agrees checkbox status, ect)
      if (typeof site.loyalty.landingLoadCallback === 'function') {
        site.loyalty.landingLoadCallback($landing);
      }

      // tiers accordian
      var $tiers = $('.loyalty_market__tier');
      $tiers.each(function(index, el) {
        var $el = $(el);
        var $control = $('.loyalty_market__tier__controller', $el);
        var $toggle = $('.loyalty_market__tier__toggle', $el);
        $control.click(function(event) {
          event.preventDefault();
          $el.toggleClass('is_open');
        });
      });

    }
  };

})(jQuery);